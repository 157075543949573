import React from 'react';
import { Link, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { defaultSerializers } from '../utils/serializers';
import { addHotspot } from '../utils/imageUrl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Pagination from '../components/pagination';
import formatRFC7231 from 'date-fns/formatRFC7231';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import toPlainText from '../utils/toPlainText';
import truncate from 'lodash/truncate';
import { url } from 'inspector';

const JournalList = props => {
  const { data } = props;
  const journalEntries = data.allSanityJournal.edges;
  const { _rawIntro, bannerImage } = data.sanityPage;

  return (
    <Layout
      headerImage={addHotspot(
        bannerImage.asset.fluid.src,
        data.sanityPage.bannerImage.hotspot
      )}
      dominantColour={bannerImage.asset.metadata.palette.dominant.background}
      textColour={bannerImage.asset.metadata.palette.dominant.title}
    >
      <SEO title="Journal" />
      <article className="page-content page-content--journal-list">
        <header>
          <h1>Journal</h1>
        </header>
        {Boolean(_rawIntro) && (
          <BlockContent blocks={_rawIntro} serializers={defaultSerializers} />
        )}
        <ul className="journal__list">
          {journalEntries.map(({ node }) => (
            <li
              className="journal__list__entry"
              key={node.id}
              style={{
                backgroundImage: `url(${addHotspot(
                  node.mainImage.asset.fixed.src,
                  data.sanityPage.bannerImage.hotspot
                )})`,
              }}
            >
              <Link
                to={`/journal/${node.slug.current}/`}
                className="journal-link"
              >
                <h2 className="journal__title">{node.title}</h2>
                <p>
                  {truncate(toPlainText(node._rawContent), { length: 160 })}{' '}
                  <span className="read-more-link">Read&nbsp;more</span>
                </p>
                <span className="journal__date">
                  Posted{' '}
                  <time
                    className="dt-published"
                    dateTime={node.date}
                    title={formatRFC7231(Date.parse(node.date))}
                  >
                    {formatDistanceToNow(Date.parse(node.date), {
                      addSuffix: true,
                    })}
                  </time>
                </span>
              </Link>
            </li>
          ))}
        </ul>
        <Pagination {...props.pageContext} />
      </article>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Layout>
  );
};

export const pageQuery = graphql`
  query SanityJournalQuery($limit: Int!, $skip: Int!) {
    sanityPage(slug: { current: { eq: "journal" } }) {
      title
      _rawIntro(resolveReferences: { maxDepth: 5 })
      bannerImage {
        hotspot {
          x
          y
        }
        asset {
          metadata {
            palette {
              dominant {
                background
              }
            }
          }
          fluid(maxWidth: 1600, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityJournal(
      sort: { order: DESC, fields: [date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          date
          mainImage {
            hotspot {
              x
              y
            }
            asset {
              fixed(width: 150, height: 350) {
                ...GatsbySanityImageFixed
              }
            }
          }
          _rawContent(resolveReferences: { maxDepth: 1 })
        }
      }
    }
  }
`;

export default JournalList;
